<template>
  <div class="soil-detect">
    <div class="soil-detect-wrap">
      <div>
        <table class="soil-detect-thead">
          <thead>
            <tr>
              <th width="25%">
                <div>检测日期</div>
              </th>
              <th width="16%">
                <div>碱解氮</div>
                <div>(mg/kg)</div>
              </th>
              <th width="16%">
                <div>有效磷</div>
                <div>(mg/kg)</div>
              </th>
              <th width="16%">
                <div>速效钾</div>
                <div>(mg/kg)</div>
              </th>
              <th width="16%">
                <div>有机质</div>
                <div>(g/kg)</div>
              </th>
              <th>
                <div>pH值</div>
              </th>
            </tr>
          </thead>
        </table>
        <table class="soil-detect-tbody">
          <tbody v-if="soilDetect.length > 0">
            <tr v-for="(item, index) in soilDetect" :key="index">
              <td width="25%">
                {{ item.detect_date.slice(0, 10) }}
              </td>
              <td width="16%">
                {{ item.dan }}
              </td>
              <td width="16%">
                {{ item.lin }}
              </td>
              <td width="16%">
                {{ item.jia }}
              </td>
              <td width="16%">
                {{ item.youjizhi }}
              </td>
              <td>
                {{ item.ph }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="6">暂无土壤检测记录</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getSoildetect } from "@/service/serverPage/fieldDetail.js";
export default {
  name: "SoilDetect",
  props: ["landId"],
  data() {
    return {
      soilDetect: []
    };
  },
  created() {
    getSoildetect(this.landId).then((res) => {
      this.soilDetect = res.data;
    });
  }
};
</script>

<style lang="scss" scoped>
.soil-detect {
  box-sizing: border-box;
  color: #333;
  table.soil-detect-thead,
  table.soil-detect-tbody {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    th > div {
      font-size: 14px;
    }
    th,
    td {
      font-size: 14px;
      border: 1px solid #aaa;
      padding: 5px 0;
    }
    th {
      border-bottom: none;
    }
  }
}
</style>