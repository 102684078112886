<template>
  <div>
    <van-loading v-show="!forcastData" class="loading" />
    <div v-show="forcastData" ref="echartsdiv" id="echartsdiv"></div>
  </div>
</template>

<script>
import { get7days } from '@/service/forcastWeather';
import dayjs from 'dayjs';
import { Loading } from 'vant';
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
// import * as echarts from 'echarts/core';
// // 引入线图图表，图表后缀都为 Chart
// import { LineChart } from 'echarts/charts';
// // 引入提示框，标题，直角坐标系组件，组件后缀都为 Component
// import { GridComponent } from 'echarts/components';
// // 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
// import { CanvasRenderer } from 'echarts/renderers';

// // 注册必须的组件
// echarts.use([LineChart, GridComponent, CanvasRenderer]);

export default {
  name: 'MaxAndMinTemp',
  components: {
    VanLoading: Loading
  },
  props: {
    lat: Number,
    lng: Number
  },
  data() {
    return {
      dayjs: dayjs,
      forcastData: null
    };
  },
  watch: {
    lat: function () {
      get7days(this.lng, this.lat).then(
        (res) => {
          if (res.code === 200 && res.message === '成功') {
            this.forcastData = res.data.weatherForecast;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
    forcastData: function (newValue) {
      if (newValue) {
        const { timeline, dayTempList, nightTempList } =
          this.handleWeather(newValue);
        this.setEchats(this.myChart, {
          xAxis: {
            data: timeline
          },
          yAxis: {
            name: '温度',
            min: function (value) {
              return value.min - 5;
            },
            max: function (value) {
              return value.max + 5;
            },
            axisLine: {
              show: true
            },
            axisLabel: {
              show: true,
              formatter: '{value}℃'
            },
            splitLine: {
              show: true
            }
          },
          series: [
            {
              label: {
                show: true,
                position: 'top',
                fontSize: 12,
                formatter: '{c}℃'
              },
              name: '最高气温',
              type: 'line',
              data: dayTempList
            },
            {
              label: {
                show: true,
                position: 'bottom',
                fontSize: 12,
                formatter: '{c}℃'
              },
              name: '最低气温',
              type: 'line',
              data: nightTempList
            }
          ]
        });
      }
    }
  },
  computed: {},
  methods: {
    initEcharts() {
      const myChart = this.$echarts.init(this.$refs.echartsdiv);
      this.myChart = myChart;
      const dayjs = this.dayjs;
      myChart.setOption({
        grid: { top: 30, bottom: 30 },
        legend: {
          data: ['最高气温', '最低气温']
        },
        xAxis: {
          type: 'category',
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            interval: 0,
            formatter: function (value) {
              return dayjs(value).format('MM/DD');
            }
          },
          axisTick: {
            show: true
          }
        },
        yAxis: {
          name: '温度',
          axisLine: {
            show: true
          },
          axisLabel: {
            show: true,
            formatter: '{value}℃'
          },
          splitLine: {
            show: true
          }
        },
        series: []
      });
    },
    setEchats(target, options) {
      target.setOption(options);
    },
    handleWeather(data) {
      const timeline = [];
      const dayTempList = [];
      const nightTempList = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        timeline.push(element.time);
        dayTempList.push(
          parseFloat(element.dayTemp === '9999' ? '' : element.dayTemp)
        );
        nightTempList.push(
          parseFloat(element.nightTemp === '9999' ? '' : element.nightTemp)
        );
      }
      return { timeline, dayTempList, nightTempList };
    }
  },
  created() {},
  mounted() {
    this.initEcharts();
  }
};
</script>
<style lang='scss' scoped>
$color: #5352ed;
#echartsdiv {
  width: 350px;
  height: 250px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
</style>